import React, {useState, useEffect} from 'react'
import axios from'axios'

const Images = (dataFrom) =>{
    const [images, setImages] = useState([])
    const [error, setError] = useState("")
  
    const [data7, setData7] = useState("")
    const [data8, setData8] = useState("")
    
    const [data55, setData55] = useState("")
    const [data65, setData65] = useState("")
    const [data95, setData95] = useState("")
    const [data1965, setData1965] = useState(dataFrom.data)
  
    const jnfrei4 = process.env.REACT_APP_1
    const v09ns9 = process.env.REACT_APP_2
    const vdsr4 = process.env.REACT_APP_4
    const d093 = process.env.REACT_APP_5
  
    const vs4ewcs = process.env.REACT_APP_0
  
    const wrgfuifb80 = process.env.REACT_APP_49
    const fouwrbf098 = process.env.REACT_APP_499
    const vksj234 = process.env.REACT_APP_499999
  
    const dsnwn430 = process.env.REACT_APP_6453
    const gsfnoifn34 = process.env.REACT_APP_667
    const fsnifs = process.env.REACT_APP_777
  
    const DataMarker = async(request, data) =>{
      switch(request){
              case 13: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            "data1965" : data,
                                            [vdsr4] : {
                                                [d093] : fouwrbf098
                                            }
                                        }).then(Item =>{
                                            if(Item.data.request){
                                              setError("")
                                              setImages(Item.data.response)
                                              return null
    
                                            }else{
                                              setError(Item.data.response)
                                              return null
                                            }
  
                                        }).catch(Error =>{
                                          setError("Error retrieving all images - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error retrieving all images - 2")
                                        return false
                                      }
                    return null
      }
    }
  
    const ZZ = (data) =>{
        DataMarker(13,data)

    }
    
  function INeedThisToDoSometing(inmyelement) {
    let bobbyd = inmyelement.files[0];
    let yomama = new FileReader();
    yomama.onloadend = function() {
        setData55(yomama.result)
    }
    yomama.readAsDataURL(bobbyd);
    
  }

  const DataMark = async(e, request, data) =>{
    e.preventDefault()

    switch(request){   
            case 14: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : vksj234,
                                              [fsnifs] : {
                                                "Image_ID" : data
                                              }
                                          }
                                      }).then(Item =>{
                                          setError("")
                                          if(Item.data.request){
                                            setData7(Item.data.response.Item.Name)
                                            setData8(Item.data.response.Item.Description)
                                            setData95(Item.data.response._id)
                                            setData65(Item.data.response.Item.Image)
                                            setError("")
                                            return null
                                          }
                                          else{
                                            setError(Item.data.response)
                                            return null
                                          }

                                      }).catch(Error =>{
                                          setError("Error retrieving image - 1")
                                          return false

                                      })
                                    }catch(Error){
                                      setError("Error retrieving image - 2")
                                      return false

                                    }
                  return null

                  case 15: 
                  try{
                      const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                              [jnfrei4] : dsnwn430,
                                              [v09ns9] : gsfnoifn34,
                                              data1965,
                                              [vdsr4] : {
                                                  [d093] : "18",
                                                  [fsnifs] : {
                                                    "Image" : data55,
                                                    "Name" : data7,
                                                    "Description" : data8,
                                                    "Image_ID" : data95

                                                  }
                                              }
                                          }).then(Item =>{
                                            if(Item.data.request){
                                              DataMarker(13, data1965)
                                              setData7("")
                                              setData8("")
                                              setError("")
                                              return null
                                            }
                                            else{
                                              setError(Item.data.response)
                                              return null
                                            }
    
                                          }).catch(Error =>{
                                            setError("Error updating image - 1")
                                            return false
    
                                          })
                                        }catch(Error){
                                          setError("Error updating image - 2")
                                          return false
                                        }
                      return null

                      case 16: 
                      try{
                          const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                                  [jnfrei4] : dsnwn430,
                                                  [v09ns9] : gsfnoifn34,
                                                  data1965,
                                                  [vdsr4] : {
                                                      [d093] : wrgfuifb80,
                                                      [fsnifs] : {
                                                        "Image" : data55,
                                                        "Name" : data7,
                                                        "Description" : data8
                                                      }
                                                  }
                                              }).then(Item =>{
                                                if(Item.data.request){
                                                  DataMarker(13, data1965)
                                                  setError("")
                                                  return null
                                                }
                                                else{
                                                  setError(Item.data.response)
                                                  return null
                                                }
        
                                              }).catch(Error =>{
                                                setError("Error creating image - 1")
                                                return false
        
                                              })
                                            }catch(Error){
                                              setError("Error creating image - 2")
                                              return false
                                            }
                          return null
    
            case 17: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : wrgfuifb80,
                                              [fsnifs] : {
                                                "Image_ID" : data
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          DataMarker(13, data1965)
                                          setError("")
                                          return null
                                        }
                                        else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch((Error) =>{
                                        setError("Error deleting image - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error deleting image - 2")
                                      return false
                                    }
                  return null
            
          default : 
              return false
        }
  
  }
    useEffect(()=>{
      DataMarker(13,data1965)

    },[])
    return (
          <div className="container">
            <div className="page_name">Manage Images</div>
                          {
                            error?
                            <div>
                              {error} hello
                            </div>
                            : <div></div>
                          }
            <div className="">
                                  {
                                    error?
                                    <div>
                                      {error}
                                    </div>
                                    : <div className="error_blank"></div>
                                  }
              <div className="table">
                <table>
                  <thead>
                        <tr>
                          <th>
                          {
                            data95 !==""?
                            <button className="button-2" onClick={(e)=>{DataMark(e, 15)}}>Update</button>
                            : <div></div>
                          }
                          {
                            data95 === ""?
                            <button className="button-2" onClick={(e)=>{DataMark(e, 16)}}>Create</button>
                            : <div></div>

                          }
                          </th>
                          <th>
                            <input type="text" name="" id="" placeholder="Image Name" onChange={(e)=>{setData7(e.target.value)}} value={data7}/>
                          </th>
                          <th>
                            <textarea name="" id="" placeholder="Image Description" onChange={(e)=>{setData8(e.target.value)}} value={data8}></textarea>
                          </th>
                          <th>
                            <input className="file-input" type="file" name="" id="" onChange={(e)=>{INeedThisToDoSometing(e.target)}}/>
                          </th>
                      </tr>
                  </thead>
                  <thead>
                            <tr>
                              <th>Edit</th>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Image</th>
                            </tr>
                  </thead>
                  <tbody>
                      {
                        images.map((Item, id)=>{
                          return(
                            <tr key={id}>
                                <td>
                                  <button className="button-2" onClick={(e)=>{DataMark(e, 14, Item._id)}}>Edit</button>
                                  <button className="button-2" onClick={(e)=>{DataMark(e, 17, Item._id)}}>Delete</button>
                                </td>
                                <td>{Item.Item.Name}</td>
                                <td>{Item.Item.Description}</td>
                                <td>
                                  <button className="button-2" onClick={(e)=>{DataMark(e, 14, Item._id)}}>View Image</button>
                                </td>
                            </tr>
                          )
                        })
                      }
                  </tbody>  
                </table>
              </div>
            </div>  
          </div>
      )
}
export default Images