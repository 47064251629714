import React, {useState, useEffect} from 'react'
import axios from'axios'

const Reviews = (dataFrom) =>{
    const [reviews, setReviews] = useState([])
    const [error, setError] = useState("")
    const [data1965, setData1965] = useState(dataFrom.data)
  
    const jnfrei4 = process.env.REACT_APP_1
    const v09ns9 = process.env.REACT_APP_2
    const vdsr4 = process.env.REACT_APP_4
    const d093 = process.env.REACT_APP_5
  
    const vs4ewcs = process.env.REACT_APP_0
    const giosnf = process.env.REACT_APP_444
    const vcxiob9 = process.env.REACT_APP_555
    const h09vs = process.env.REACT_APP_666
    const oibu0943 = process.env.REACT_APP_7778
  
    const dsnwn430 = process.env.REACT_APP_6453
    const gsfnoifn34 = process.env.REACT_APP_667
    const fsnifs = process.env.REACT_APP_777
  
    const DataMarker = async(request, data) =>{
      switch(request){
          case 0: 
          try{
              const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                      [jnfrei4] : dsnwn430,
                                      [v09ns9] : gsfnoifn34,
                                      "data1965" : data,
                                      [vdsr4] : {
                                          [d093] : giosnf,
                                      }
                                  }).then(Item =>{
                                      if(Item.data.request){
                                        setError("")
                                        setReviews(Item.data.response)
                                        return null
  
                                      }else{
                                        setError(Item.data.response)
                                        return null
                                      }
      
                                  }).catch(Error =>{
                                      setError("Error retrieving all reviews - 1")
                                      return false
      
                                  })
                                }catch(Error){
                                  setError("Error retrieving all reviews - 2")
                                  return false
  
                                }
              return null
      }
    }
  
    const ZZ = (data) =>{
        DataMarker(0,data)
    }
  
    const DataMark = async(e, request, data) =>{
      e.preventDefault()
  
      switch(request){   
              case 1: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            data1965,
                                            [vdsr4] : {
                                                [d093] : vcxiob9,
                                                [fsnifs] : {
                                                  "Review_ID" : data
  
                                                }
                                            }
                                        }).then(Item =>{
                                            if(Item.data.request){
                                              setError("")
                                              DataMarker(0, data1965)
                                              return null
                                            }
                                            else{
                                              setError(Item.data.response)
                                              return null
                                            }
                                            return null
  
                                        }).catch(Error =>{
                                          setError("Error approving review - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error approving review - 2")
                                        return false
                                      }
                    return null
  
              case 2: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            data1965,
                                            [vdsr4] : {
                                                [d093] : h09vs,
                                                [fsnifs] : {
                                                  "Review_ID" : data
                                                }
                                            }
                                        }).then(Item =>{
                                          if(Item.data.request){
                                            setError("")
                                            DataMarker(0, data1965)
                                            return null
                                          }
                                          else{
                                            setError(Item.data.response)
                                            return null
                                          }
  
                                        }).catch(Error =>{
                                          setError("Error denying review - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error denying review - 2")
                                        return false
                                      }
                    return null
  
              case 3: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            data1965,
                                            [vdsr4] : {
                                                [d093] : oibu0943,
                                                [fsnifs] : {
                                                  "Review_ID" : data
                                                }
                                            }
                                        }).then(Item =>{
                                          if(Item.data.request){
                                            setError("")
                                            DataMarker(0, data1965)
                                            return null
                                          }
                                          else{
                                            setError(Item.data.response)
                                            return null
                                          }
  
                                        }).catch(Error =>{
                                          setError("Error deleting review - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error deleting review - 2")
                                        return false
  
                                      }
                    return null
            default : 
                return false
          }
    
    }
    useEffect(()=>{
        DataMarker(0,data1965)

    },[])
   
    return (
            <div className="container">
                <div className="page_name">Huduma Homecare Reviews</div>
                                    {
                                        error?
                                        <div>
                                        {error}
                                        </div>
                                        : <div className="error_blank"></div>
                                    }  
                <div className="about_description">
                <div className="table"><table>
                                    <thead>
                                        <tr>
                                            <th className="review_edit">Edit</th>
                                            <th>For</th>
                                            <th>Review</th>
                                            <th>Name</th>
                                            <th className="review_status">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {reviews.map((Item, id)=>{
                                        return (
                                            <tr key={id}>
                                                <td>
                                                <button className="button-2" onClick={(e)=>{DataMark(e, 1, Item._id)}}>Approve</button>
                                                <button className="button-2" onClick={(e)=>{DataMark(e, 2, Item._id)}}>Deny</button>
                                                <button className="button-2" onClick={(e)=>{DataMark(e, 3, Item._id)}}>Delete</button>
                                                <a href={`mailto:${Item.Item.E_mail}`} className="button-2">E-mail</a>
                                                </td>
                                                <td>{Item.Item.For}</td>
                                                <td>{Item.Item.Review}</td>
                                                <td>{Item.Item.First_Name} {Item.Item.Last_Name}</td>
                                                <td>{Item.Item.Status}</td>
                                            </tr>)
                                        }
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                </div>
                
            </div>
        )
}

export default Reviews

