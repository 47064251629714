import React, {useState, useEffect} from 'react'
import axios from'axios'

const Team = (dataFrom) =>{
    const [caregivers, setCaregiver] = useState([])
    const [images, setImages] = useState([])
    const [error, setError] = useState("")
  
    const [data1, setData1] = useState("")
    const [data2, setData2] = useState("")
    const [data3, setData3] = useState("")
    const [data4, setData4] = useState("")
  
    const [data1965, setData1965] = useState(dataFrom.data)
  
    const [data555, setData555] = useState("")
  
    const jnfrei4 = process.env.REACT_APP_1
    const v09ns9 = process.env.REACT_APP_2
    const vdsr4 = process.env.REACT_APP_4
    const d093 = process.env.REACT_APP_5
  
    const coivvsd = process.env.REACT_APP_6
    const s0ss8vds = process.env.REACT_APP_7
    const ds089 = process.env.REACT_APP_8
    const s890vs = process.env.REACT_APP_9
  
    const vs4ewcs = process.env.REACT_APP_0
  
    const vio00 = process.env.REACT_APP_11
  
    const dsnwn430 = process.env.REACT_APP_6453
    const gsfnoifn34 = process.env.REACT_APP_667
    const fsnifs = process.env.REACT_APP_777
  
    const DataMarker = async(request, data) =>{
      switch(request){
              case 6: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            "data1965" : data,
                                            [vdsr4] : {
                                                [d093] : s0ss8vds
                                            }
  
                                        }).then(Item =>{
                                            if(Item.data.request){
                                              //console.log(Item.data.request)
                                              if(Item.data.response !== undefined){
                                                setError("")
                                                setCaregiver(Item.data.response)
                                              }else{
                                                setError("Error retrieving caregiver profiles!")
                                                return null
                                              }
  
                                            }else{
                                              setError(Item.data.response)
                                              return null
                                            }
  
                                        }).catch((Error) =>{
                                          setError("Error retrieving all caregivers - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error retrieving all caregivers - 2")
                                        return false
  
                                      }
                    return null
  
      }
    }
  
    const ZZ = (data) =>{
        DataMarker(6,data)

    }
    
  const DataMark = async(e, request, data) =>{
    e.preventDefault()
    switch(request){ 
            case 7: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : ds089,
                                              [fsnifs] : {
                                                Caregiver_ID : data
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          setError("")
                                          setData1(Item.data.response.Item.First_Name)
                                          setData2(Item.data.response.Item.Last_Name)
                                          setData4(Item.data.response.Item.Description)
                                          setData555(Item.data.response._id)
                                          return null
                                        }
                                        else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        setError("Error retrieving caregiver - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error retrieving caregiver - 2")
                                      return false
                                    }
                  return null

            case 8: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : coivvsd,
                                              [fsnifs] : {
                                                "First_N" : data1,
                                                "Last_N" : data2,
                                                "Image_ID" : data3,
                                                "Description" : data4
                                                
                                              }
                                          }
                                      }).then(Item =>{
                                          if(Item.data.request){
                                            setError("")
                                            DataMarker(6, data1965)
                                            setData1("")
                                            setData2("")
                                            setData3("")
                                            setData4("")
                                            return null
                                          }
                                          else{
                                            setError(Item.data.response)
                                            return null
                                          }

                                      }).catch(Error =>{
                                        setError("Error creating caregiver - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error creating caregiver - 2")
                                      return false
                                    }
                  return null

            case 9: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : s890vs,
                                              [fsnifs] : {
                                                "First_N" : data1,
                                                "Last_N" : data2,
                                                "Image_ID" : data3,
                                                "Description" : data4,
                                                "Caregiver_ID" : data555
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          setError("")
                                          DataMarker(6, data1965)
                                          setData1("")
                                          setData2("")
                                          setData3("")
                                          setData4("")
                                          setData555("")
                                          return null
                                        }
                                        else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        setError("Error updating caregiver - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error updating caregiver - 2")
                                      return false
                                    }
                  return null

            case 10: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : vio00,
                                              [fsnifs] : {
                                                Caregiver_ID : data
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          setError("")
                                          DataMarker(6, data1965)
                                          return null
                                        }
                                        else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        setError("Error removing caregiver - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error removing caregiver - 2")
                                      return false
                                    }
                  return null

          default : 
              return false
        }
  
  }
    useEffect(()=>{
        DataMarker(6,data1965)

    })
    return(
          <div className="container">
            <div className="page_name">Manage Team Profiles</div>
                                {
                                    error?
                                    <div>
                                      {error}
                                    </div>
                                    : <div className="error_blank"></div>
                                  }            
            <div>
                <div className="table">
                  <table>
                    <thead>
                        <tr>
                          <th>
                            {
                              data555 !==""?
                              <button className="button-2" onClick={(e)=>{DataMark(e, 9)}}>Update</button>
                              : <div></div>
                            }
                            {
                              data2 !==""?
                              <button className="button-2" onClick={(e)=>{DataMark(e, 8)}}>Create</button>
                              : <button className="button-2 blank-button"></button>
                              
                            }
                          </th>
                          <th>
                            <input type="text" placeholder="First Name" onChange={(e)=>{setData1(e.target.value)}} value={data1}/>
                            <input type="text" placeholder="Last Name" onChange={(e)=>{setData2(e.target.value)}} value={data2}/>
                          </th>
                          <th>
                            <select name="" id="" onChange={(e)=>{setData3(e.target.value)}} value={data3}>
                              {
                                images.map((Item, id)=>{
                                  return(
                                      <option key={id} value={Item._id}>{Item.Item.Name}</option>
                                  )
                                })
                              }
                            </select></th>
                          <th>
                            <textarea name="" id="" placeholder="Description" onChange={(e)=>{setData4(e.target.value)}} value={data4}></textarea>
                          </th>
                        </tr>
                        <tr>
                            <th className="">Edit</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                          caregivers.map((Item, id)=>{
                            return(
                                <tr key={id}>
                                  <td>
                                  {
                                    error?
                                    <div>
                                      {error}
                                    </div>
                                    : <div></div>
                                  }
                                  <button className="button-2" onClick={(e)=>{DataMark(e, 7, Item._id)}}>Edit</button>
                                  <button className="button-2" onClick={(e)=>{DataMark(e, 10, Item._id)}}>Delete</button>
                                  </td>
                                  <td>{Item.Item.First_Name} {Item.Item.Last_Name}</td>
                                  <td>
                                    image
                                  </td>
                                  <td>
                                    <p>{Item.Item.Description}</p>
                                  </td>
                                </tr>
                            )
                          })
                        }
                    </tbody>  
                  </table>
                </div>
            </div>
          </div>
      )
}

export default Team