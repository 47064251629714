import React, {useState, useEffect} from 'react'
import axios from'axios'

const Resumes = (dataFrom) => {
    
    const [resumes, setResumes] = useState([])
    const [error, setError] = useState("")
  
    const [data1965, setData1965] = useState(dataFrom.data)
  
    const jnfrei4 = process.env.REACT_APP_1
    const v09ns9 = process.env.REACT_APP_2
    const vdsr4 = process.env.REACT_APP_4
    const d093 = process.env.REACT_APP_5
  
    const vs4ewcs = process.env.REACT_APP_0
  
    const rdf09 = process.env.REACT_APP_22
    const vsf90 = process.env.REACT_APP_33
  
    const dsnwn430 = process.env.REACT_APP_6453
    const gsfnoifn34 = process.env.REACT_APP_667
    const fsnifs = process.env.REACT_APP_777
  
    const DataMarker = async(request, data) =>{
      switch(request){
              case 4: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            "data1965" : data,
                                            [vdsr4] : {
                                                [d093] : rdf09
                                            }
                                        }).then(Item =>{
                                          if(Item.data.request){
                                            setError("")
                                            setResumes(Item.data.response)
                                            return null
  
                                          }else{
                                            setError(Item.data.response)
                                            return null
                                          }
  
                                        }).catch(Error =>{
                                          setError("Error retrieving all resumes - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error retrieving all resumes - 2")
                                        return false
  
                                      }
                    return null
      }
    }

  const DataMark = async(e, request, data) =>{
    e.preventDefault()
    
    switch(request){  
            case 5: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : vsf90,
                                              [fsnifs] : {
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          setError("")
                                          return null
                                        }
                                        else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        setError("Error retrieving resume - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error retrieving resume - 2")
                                      return false
                                    }
                  return null

          default : 
              return false
        }
  
  }
 
    useEffect(()=>{
        DataMarker(4,data1965)

    },[])
    return (
          <div className="container">  
            <div className="page_name">
              Manage Caregiver Resumes
            </div>
                                {
                                    error?
                                    <div>
                                      {error}
                                    </div>
                                    : <div className="error_blank"></div>
                                  }  
            <div className="">
                        <div className="table"><table>
                                <thead>
                                    <tr>
                                        <th className="">Edit</th>
                                        <th>Name</th>
                                        <th>E-mail</th>
                                        <th>Phone</th>
                                        <th>Position</th>
                                        <th>Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                      resumes.map((Item, id)=>{
                                        return(
                                            <tr key={id}>
                                              <td>
                                              <a href="" className="button-2">View Resume</a>
                                              </td>
                                              <td>{Item.Item.First_Name} {Item.Item.Last_Name}</td>
                                              <td>
                                                <a href={`mailto:${Item.Item.E_mail}`} className="button-2">E-mail</a>
                                              </td>
                                              <td>{Item.Item.Phone}</td>
                                              <td>{Item.Item.Position}</td>
                                              <td>
                                                <a href="" className="button-2">View Image</a>
                                              </td>
                                            </tr>
                                        )
                                      })
                                    }
                                </tbody>
                            </table>
                        </div>
            </div>
          </div>
      )   
}
export default Resumes