import './App.css';
import './style.css'
import './buttons.css'
import React, {useState, useEffect} from 'react'
import axios from'axios'
import Reviews from './components/reviews/reviews.jsx'
import Resume from './components/resume/resume'
import Team from './components/caregivers/caregivers.jsx'
import Contacts from './components/contacts/contact.jsx'
import Images from './components/manage_images/manage.jsx'

function App() {

  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const validPnum = new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$");
 
  const [choice, setChoice] = useState("")
  const [error, setError] = useState("")

  const [data11 , setData11 ] = useState("")
  const [data22 , setData22 ] = useState("")
  const [data111 , setData111 ] = useState("")
  const [data222 , setData222 ] = useState("")
  const [data1965, setData1965] = useState("")
  const [data33 , setData33 ] = useState("")
  const [data44 , setData44 ] = useState("")
  const [data66 , setData66 ] = useState("")
  const [data77 , setData77 ] = useState("")

  const jnfrei4 = process.env.REACT_APP_1
  const v09ns9 = process.env.REACT_APP_2
  const vdsr4 = process.env.REACT_APP_4
  const d093 = process.env.REACT_APP_5
  const dsnwn430 = process.env.REACT_APP_6453
  const gsfnoifn34 = process.env.REACT_APP_667
  const vs4ewcss = process.env.REACT_APP_00

  const BB = async(e) =>{
    e.preventDefault()
    try {
      const A = await axios.post(`${vs4ewcss}`,{
                              [jnfrei4] : dsnwn430,
                              [v09ns9] : gsfnoifn34,
                              [vdsr4] : {
                                  [d093] : 1,
                                  data111,
                                  data222,
                              }
  
                            }).then((Item)=>{
                                if(Item.data.request){
                                  setError("")
                                  setData111("") 
                                  setData222("")
                                  setData1965(Item.data.response)
                                  setChoice(1)
                                  return null
                                }
                                else{
                                  setError(Item.data.response)
                                  return null
                                }
  
                            }).catch((Error)=>{
                              setError("Error logging!")
                              return null
                              
                            })
    return null
    } catch (error) {
      setError("Error logging in")
      return null
      
    }
  }

  const CC = async(e) =>{
    e.preventDefault()
    try {
      if(data66 !== data77){
        setError("Passwords do not match!")
        return null

      }
      if(!validEmail.test(data11)){
          setError("E-mail invalid!")
          return null

      }
      //Phone Regex
      if(!validPnum.test(data22)){
          setError("Pass must be 8 or more, 1 special, 1 number, 1 upper and lower case.")
          return null
          
      }
      setError("")
      const LL = await  axios.post(`${vs4ewcss}`,{
                              [jnfrei4] : dsnwn430,
                              [v09ns9] : gsfnoifn34,
                              [vdsr4] : {
                                  [d093] : 0,
                                  data11,
                                  data22,
                                  data33,
                                  data44,
                                  data66
                              }

                            }).then((Item)=>{
                                if(Item.data.request){
                                  setError("")
                                  setChoice("")
                                  setData11("")
                                  setData22("")
                                  setData33("")
                                  setData44("")
                                  setData66("")
                                  return null

                                }
                                else{
                                  setError(Item.data.response)
                                  return null

                                }

                            }).catch((Error)=>{
                              setError("Error creating account!")
                              return null
                              
                            })
      
    } catch (error) {
      setError("Error creating account!")
      return null
      
    }
  }

  const header =  <div className="header">
                    <h1 className="logo">Huduma Homecare Management</h1>
                    <div className="navigation_buttons">
                      <button className="button-5" onClick={(e)=>{setChoice(1)}}>Huduma Reviews</button>
                      <button className="button-5" onClick={(e)=>{setChoice(2)}}>Resumes</button>
                      <button className="button-5" onClick={(e)=>{setChoice(3)}}>Manage Caregivers</button>
                      <button className="button-5" onClick={(e)=>{setChoice(4)}}>Manage Contact Requests</button>
                      <button className="button-5" onClick={(e)=>{setChoice(5)}}>Manage Images</button>
                      <button className="button-5" onClick={(e)=>{Logout(e)}}>Logout</button>
                    </div>
                  </div>

  const Logout = async(e) =>{
    setChoice("")
    return null
    
  }

  switch(choice){
    case 1: return (
              <div>
                {header}
                <Reviews data={data1965}/>
                
              </div>
            )
            break;
    case 2: return (
              <div>
                {header}
                <Resume data={data1965}/>

              </div>
            )
            break;

    case 3: return(
              <div>
                {header}
                <Team data={data1965}/>
                
              </div>
            )
            break;

    case 4: return (
              <div>
                {header}
                <Contacts data={data1965}/>

              </div>
            )
            break;

    case 5: return (
              <div>
                {header}
                <Images data={data1965}/>

              </div>
            )
            break;

    case 6: return(
              <div>
                <div className="account_container">
                  <h1 className="page_name">Create Account</h1>
                  <div className="uin">
                    <label htmlFor="">E-mail</label>
                    <input type="text" name="" id="" onChange={(e)=>{setData11(e.target.value)}} value={data11}/>
                    
                  </div>
                  <div className="uin">
                    <label htmlFor="">Phone</label>
                    <input type="text" name="" id="" onChange={(e)=>{setData22(e.target.value)}} value={data22}/>
                    
                  </div>
                  <div className="uin">
                    <label htmlFor="">First Name</label>
                    <input type="text" name="" id="" onChange={(e)=>{setData33(e.target.value)}} value={data33}/>
                  
                  </div>
                  <div className="uin">
                    <label htmlFor="">Last Name</label>
                    <input type="text" name="" id="" onChange={(e)=>{setData44(e.target.value)}} value={data44}/>
                  
                  </div>
                  <div className="uin">
                    <label htmlFor="">Password</label>
                    <input type="password" name="" id="" onChange={(e)=>{setData66(e.target.value)}} value={data66}/>
                  
                  </div>
                  <div className="uin">
                    <label htmlFor="">Re-enter Password</label>
                    <input type="password" name="" id="" onChange={(e)=>{setData77(e.target.value)}} value={data77}/>
                  
                  </div>
                  <button className="button-2" onClick={(e)=>{CC(e)}}>Create Account</button>
                  
                  {
                    error?
                    <div>
                      {error}
                    </div>
                    : <div></div>
                  }
                  
                </div>

              </div>
            )
            break;
    default: return (
                <div>
                  <div className="container">
                    <div className="login_container">
                      <img src="" alt="Huduma Homecare Logo"/>
                      <h1>Please Login</h1>
                      <div className="uin">
                        <label htmlFor="">Username</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData111(e.target.value)}} value={data111}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Password</label>
                        <input type="password" name="" id="" onChange={(e)=>{setData222(e.target.value)}} value={data222}/>
                      </div>
                        {
                          error?
                          <div>
                            {error}
                          </div>
                          : <div></div>
                        }
                      <button className="button-2" onClick={(e)=>{BB(e)}}>Huduma Login</button>
                      <button className="button-2" onClick={(e)=>{setChoice(6)}}>Your Login</button>
                    </div>
                  </div>
                </div>
              )
              break;
  }
}

export default App;
