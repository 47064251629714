import React, {useState, useEffect} from 'react'
import axios from'axios'

const Contacts = (dataFrom) =>{
    const [contact, setContact] = useState([])
    const [error, setError] = useState("")
  
    const [data1965, setData1965] = useState(dataFrom.data)
  
    const jnfrei4 = process.env.REACT_APP_1
    const v09ns9 = process.env.REACT_APP_2
    const vdsr4 = process.env.REACT_APP_4
    const d093 = process.env.REACT_APP_5
  
    const vs4ewcs = process.env.REACT_APP_0
  
    const iubs900 = process.env.REACT_APP_8889
    const gubw903 = process.env.REACT_APP_9999
  
    const dsnwn430 = process.env.REACT_APP_6453
    const gsfnoifn34 = process.env.REACT_APP_667
  
    const DataMarker = async(request, data) =>{
      switch(request){
              case 11: 
                try{
                    const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                            [jnfrei4] : dsnwn430,
                                            [v09ns9] : gsfnoifn34,
                                            "data1965" : data,
                                            [vdsr4] : {
                                                [d093] : iubs900
                                            }
                                        }).then(Item =>{
                                            if(Item.data.request){
                                              setError("")
                                              setContact(Item.data.response)
                                              return null
    
                                            }else{
                                              setError(Item.data.response)
                                              return null
                                            }
  
                                        }).catch(Error =>{
                                          setError("Error retrieving contact requests - 1")
                                          return false
  
                                        })
                                      }catch(Error){
                                        setError("Error retrieving contact requests - 2")
                                        return false
                                      }
                    return null
      }
    }
    
  const DataMark = async(e, request, data) =>{
    e.preventDefault()
    switch(request){   
            case 12: 
              try{
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          data1965,
                                          [vdsr4] : {
                                              [d093] : gubw903
                                          }
                                      }).then(Item =>{
                                        setError("")
                                        if(Item.data.request){
                                            setError("")
                                            DataMarker(11, data1965)
                                            return null
                                        }
                                        else{
                                            setError(Item.data.response)
                                            return null
                                        }

                                      }).catch(Error =>{
                                        setError("Error removing contact request - 1")
                                        return false

                                      })
                                    }catch(Error){
                                      setError("Error removing contact request - 2")
                                      return false

                                    }
                  return null

          default : 
              return false
        }
  
  }
    useEffect(()=>{
        DataMarker(11,data1965)
        
    },[])
    return (
          <div className="container">
            <div className="page_name">Manage Contact Requests</div>
            <div className="">
                                {
                                    error?
                                    <div>
                                      {error}
                                    </div>
                                    : <div className="error_blank"></div>
                                }
                <div className="table">
                    <table>
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>E-mail</th>
                              <th>Phone</th>
                              <th>Location</th>
                              <th>Service</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody>
                        {contact.map((Item, id)=>{
                          return(
                                <tr key={id}>
                                  <td>{Item.Item.First_Name} {Item.Item.Last_Name}</td>
                                  <a href={`mailto:${Item.Item.E_mail}`} className="button-2">E-mail</a>
                                  <td>{Item.Item.Phone}</td>
                                  <td>{Item.Item.Location}</td>
                                  <td>{Item.Item.Service}</td>
                                  <td>{Item.Item.Status}</td>
                                </tr>
                          )
                        })}

                      </tbody>  
                    </table>
                  </div>
            </div>  
          </div>
      )
}

export default Contacts